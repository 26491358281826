<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2"> Custumer Addresses </h6>
      <b-col cols="12" class="mb-2">
        <b-list-group>
          <template v-for="(list, listIndex) in address">
            <template v-if="list.address.length">
              <b-list-group-item class="flex-column align-items-start" :key="listIndex" active v-if="listIndex == 0">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1 text-white">
                    {{ list.address[0].name }}
                  </h5>
                  <small class="text-secondary">25/10/2022</small>
                </div>
                <b-card-text class="mb-1">
                  {{ list.address[0].address_line1 }}, {{ list.address[0].address_line2 }},<br />
                  {{ list.address[0].county }}, {{ list.address[0].city }}, {{ list.address[0].country }}<br />
                  {{ list.address[0].zip }}
                </b-card-text>
                <small class="text-white"
                  ><a :href="'tel:' + list.address[0].mobile" class="text-white">{{ list.address[0].mobile }}</a></small
                >
              </b-list-group-item>
              <b-list-group-item class="flex-column align-items-start" :key="listIndex" v-else>
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">
                    {{ list.address[0].name }}
                  </h5>
                  <small class="text-primary">25/10/2022</small>
                </div>
                <b-card-text class="mb-1">
                  {{ list.address[0].address_line1 }}, {{ list.address[0].address_line2 }},<br />
                  {{ list.address[0].county }}, {{ list.address[0].city }}, {{ list.address[0].country }}<br />
                  {{ list.address[0].zip }}
                </b-card-text>
                <small class="text-secondary"
                  ><a :href="'tel:' + list.address[0].mobile">{{ list.address[0].mobile }}</a></small
                >
              </b-list-group-item>
            </template>
          </template>
        </b-list-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BListGroup, BListGroupItem, BCardText, BRow, BCard, BCol } from 'bootstrap-vue';

export default {
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    address() {
      return _.uniqBy(this.generalData.order, 'address_id');
    }
  },
  components: {
    BListGroup,
    BListGroupItem,
    BCardText,
    BRow,
    BCard,
    BCol
  }
};
</script>
