<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left">
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general v-if="options" :general-data="options" />
    </b-tab>
    <!--/ general tab -->
    <!-- info -->
    <b-tab v-if="options">
      <template #title>
        <feather-icon icon="PackageIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Orders</span>
      </template>

      <account-setting-information :general-data="options" />
    </b-tab>

    <!-- social links -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="HomeIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Addresses</span>
      </template>

      <account-setting-social v-if="options" :general-data="options" />
    </b-tab>

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Settings</span>
      </template>

      <account-settings v-if="options" :general-data="options" :user_id="id" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AccountSettingGeneral from "./components/customerSingle/customerGeneral.vue";
import AccountSettingInformation from "./components/customerSingle/customerOrders.vue";
import AccountSettingSocial from "./components/customerSingle/customerAddresses.vue";
import AccountSettings from "./components/customerSingle/customerSettings.vue";

import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettings,
  },
  props: ["id"],
  data() {
    return {
      options: {},
    };
  },
  async mounted() {
    await useJwt
      .get_customer(this.id)
      .then((response) => { 
        this.options = response.data.data[0];
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
  },
};
</script>
