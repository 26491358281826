<template>
  <b-card>
    <!-- form -->
    <b-form class="">
      <b-row>

        <h6 class="section-label mx-1 mb-2">
          Custumer General
        </h6>
        <b-col cols="12">
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-Mail Address" label-for="account-email">
            {{ generalData.email }}
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Full Name" label-for="account-fullname">
            {{ generalData.first_name }} {{ generalData.last_name }}
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Created At" label-for="account-created">
            {{ generalData.created_at | moment('DD/MM/YYYY') }}
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Total Orders" label-for="account-orders" v-if="generalData.order">
            {{ generalData.order.length }} Orders
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75" v-if="generalData.flag">
          <b-alert show variant="danger" class="mb-50">
            <h4 class="alert-heading">
              This account has been flagged by system administrator.
            </h4>
          </b-alert>
        </b-col>
        <!--/ alert -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' 
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => { },
    },
  }, 
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
