<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2"> Customer Settings </h6>
      <b-col cols="12" class="mb-2">
        <b-form-checkbox id="accountSwitch1" :checked="flag" v-model="flag" name="check-button" switch inline>
          <span>Red Flag</span>
        </b-form-checkbox>
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="saveSettings()"
        >
          Save changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="reset"
          class="mt-1"
          variant="outline-secondary"
          @click="$router.push('/takeaway/shop/customers/')"
        >
          Cancel
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>


import { BButton, BRow, BCol, BCard, BFormCheckbox } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import useJwt from '@/auth/jwt/useJwt';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox
  },
  directives: {
    Ripple
  },
  data() {
    return {
      flag: this.generalData.flag
    };
  },
  watch: {
    generalData(val) {
      this.$forceUpdate();

      if (val.flag == 1) {
        this.flag = 'true';
        return true;
      }
      this.flag = false;
      return false;
    }
  },
  props: {
    generalData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    async saveSettings() {
      await useJwt
        .patch_customer(this.generalData.id, {
          'flag': this.flag
        })
        .then(async response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Customer saved`,
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully updated customer.`
            }
          });
        })
        .catch(e => {
          console.log(e);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Error`,
              icon: 'CropIcon',
              variant: 'danger',
              text: `We couldn't update customer on the server. At this moment... Please try again later.`
            }
          });
        });
    }
  }
};
</script>
