<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Customer Orders</h6>
      <b-col cols="12" class="mb-2" v-if="generalData">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table :columns="columns" :rows="orders">
          <template slot="table-row" slot-scope="props">
            <!-- Column: Status -->
            <span v-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status + 1)">
                {{ status[0][props.row.status + 1] }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'sub_total'"> €{{ calcTotal(props.row) }} </span>
            <span v-else-if="props.column.field === 'id'">
              <router-link :to="'/takeaway/shop/orders/' + props.row.id">{{ props.row.id }}</router-link>
            </span>
            <span v-else-if="props.column.field === 'created_at'">
              {{ props.row.created_at | moment("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else-if="props.column.field === 'updated_at'">
              {{ props.row.updated_at | moment("DD/MM/YYYY HH:mm") }}
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button type="submit" variant="primary" block :to="'/takeaway/shop/orders/' + props.row.id">
                <feather-icon icon="BookOpenIcon" class="mr-50" />
                <span>Preview</span>
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['500', '1000', '2500']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BRow, BCard, BCol, BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BRow,
    BCard,
    BCol,
    BFormInput,
    BButton,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pageLength: 500,

      columns: [
        {
          label: "ID",
          field: "id",
          width: "80px",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Submitted",
          field: "created_at",
          width: "200px",
        },
        {
          label: "Finished",
          field: "updated_at",
          width: "200px",
        },
        {
          label: "Total",
          field: "sub_total",
          width: "120px",
        },
        {
          label: "Action",
          field: "action",
          width: "150px",
        },
      ],
      rows: [],
      searchTerm: "",

      rows: [],
      searchTerm: "",
      status: [
        {
          0: "Refunded",
          1: "Queued",
          2: "Accepted",
          3: "Out for delivery",
          4: "Completed",
          5: "Failed",
        },
        {
          0: "danger",
          1: "primary",
          2: "info",
          3: "info",
          4: "success",
          5: "danger",
        },
      ],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "danger",
        1: "primary",
        2: "info",
        3: "info",
        4: "success",
        5: "danger",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    orders() {
      if (this.generalData && this.generalData.order && this.generalData.order[0]) {
        return this.generalData.order.reverse();
      } else {
        return [];
      }
    },
  },
  methods: {
    calcTotal(row) {
      let total = parseFloat(row.sub_total);
      if (row.shipping) total += parseFloat(row.shipping);
      if (row.discount) total -= parseFloat(row.discount);

      return total.toFixed(2);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
